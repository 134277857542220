@use "sass:map";
@import "Style/pages/theme";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

body {
  font-family: "Open Sans", sans-serif;
}

.product__detail__pic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    }
  }
}

.product__detail__text {
  .detail {
    margin: 10px 50px 0;

    h2 {
      color: #252525;
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 15px;
    }

    h3 {
      color: map.get($theme-colors, "main");
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      margin: 10px 0;
      line-height: 1.6;
      font-size: 16px;
      color: #757575;
    }

    ul {
      margin: 20px 0;
      padding-top: 20px;
      border-top: 1px solid #eee;

      li {
        display: flex;
        column-gap: 20px;
        line-height: 35px;
        font-size: 16px;

        b {
          width: 150px;
          font-weight: 600;
        }

        span {
          color: #4caf50;
          font-weight: 500;
        }
      }
    }

    .add-to-cart {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;

      input {
        width: 60px;
        padding: 5px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 5px;
        text-align: center;

        &:focus {
          border-color: map.get($theme-colors, "main");
          outline: none;
        }
      }

      button {
        padding: 8px 15px;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        background-color: map.get($theme-colors, "main");
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken(map.get($theme-colors, "main"), 10%);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .product__detail__text .detail {
    margin: 0 20px;

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    p, ul li {
      font-size: 14px;
    }
  }
}

@media (max-width: 576px) {
  .product__detail__text .detail {
    margin: 0 10px;

    .add-to-cart {
      flex-direction: column;
      input,
      button {
        width: 100%;
      }
    }
  }
}
