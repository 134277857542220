@use "sass:map";
@import "Style/pages/theme";
@import url('http://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {font-family:  "Open Sans", sans-serif;}

.breadcrumb {
    display: flex;   
    align-items: center;
    text-align: center;
    padding: 10px 0px 10px;
    justify-content: center;
    line-height: 30px;
    background: map.get($theme-colors, "main");

    .breadcrumb__text {
        color: #ffffff;

        h2 {
            margin-bottom: 10px;
        }

        ul {
            display: flex;
            list-style: none;
            column-gap: 10px;

            li {
                text-decoration: underline;
                color: map.get($theme-colors, "description");

                a {
                    text-decoration: none;
                    color: #ffffff;
                }

                &.link {
                    text-decoration: none;

                    :hover {
                       color: map.get($theme-colors, "description");
                    }

                }
            }
        }
    }
}