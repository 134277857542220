.customize-slider {
    padding: 20px 0; // Space around the slider
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color); // Background color variable
    border-radius: 10px; // Rounded corners
    margin: 20px 0; // Vertical spacing
}

.customize-text {
    max-width: 1500px; // Maximum width for text content
    margin: 20px auto; // Center the text block
    padding: 0 15px; // Horizontal padding
    line-height: 1.6; // Improved line spacing for readability
    font-size: 1rem; // Base font size
    color: #333; // Text color

    h2 {
        margin-top: 30px; // Space above headings
        margin-bottom: 15px; // Space below headings
        font-size: 1.5rem; // Size for headings
        color: var(--primary-color); // Primary color for headings
    }

    p {
        margin-bottom: 15px; // Space between paragraphs
    }

    img {
        
        width: 600px; // Responsive image
        height: auto; // Maintain aspect ratio
        margin: 20px 0px 20px 450px; // Space around images
        border-radius: 5px; // Slight rounding for images
    }
}

// Responsive Design
@media (max-width: 768px) {
    .customize-slider {
        padding: 15px 0; // Reduced padding on smaller screens
    }

    .customize-text {
        padding: 0 10px; // Less padding on small screens
        font-size: 0.95rem; // Slightly smaller font size
    }

    h2 {
        font-size: 1.3rem; // Smaller heading size on small screens
    }
}
