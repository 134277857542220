@use "sass:map";
@import "Style/pages/all";
@import url('http://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {font-family:  "Open Sans", sans-serif;}

$bannerImg: "/Assets/users/images/hero/banner.jpg";

.header__top {
    background: #f5f5f5;
    margin-top: 10px;
}

.header__top_left {
    ul {
        display: flex;
        align-items: center;
        list-style: none;
        column-gap: 20px;

        li {
            font-size: 14px;
            color: map.get($theme-colors, "normal-text");
            display: flex;
            align-items: center;
            column-gap: 2px;
        }

        li:first-child {
            position: relative;

            &:after {
                position: absolute;
                right: -10px;
                top: 1px;
                height: 20px;
                width: 1px;
                background: #000000;
                opacity: 0.1;
                content: "";
            }
        }
    }
}

.header__top_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ul {
        display: flex;
        column-gap: 5px;
        list-style: none;

        a {
            font-size: 14px;
            display: inline-block;
            color: map.get($theme-colors, "normal-text");
        }

        li:last-child {
            cursor: pointer;
            margin-left: 10px;
        }

        span {
            font-size: 14px;
            margin-left: 2px;
        }
    }
}

.header__logo {
    img {
        max-width: 100%; 
        width: 40%;
        height: 20%;     
        display: block;   
        margin: 0 auto;
        border-radius: 50%;
    }
}


.header__menu {
    padding: 24px 0;

    ul {
        display: flex;
        justify-content: space-between;

        li {
            list-style: none;
            position: relative;

            a {
                text-decoration: none;
                font-size: 17px;
                color: map.get($theme-colors, "bold-text");
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 2px;
                transition: all 0.3s;
                padding: 5px 0;
                display: block;
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 2px;
                    background: map.get($theme-colors, "main");
                    transition: width 0.3s ease;
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                }

                &:hover {
                    color: map.get($theme-colors, "main");
                    background: rgba(map.get($theme-colors, "main"), 0.1);
                    padding: 5px 15px;
                    border-radius: 5px;
                }

                &:hover:after {
                    width: 100%;
                }
            }
        }

        .header__menu__dropdown {
            background: #222222;
            display: block;
            position: absolute;
            width: 200px;
            z-index: 9;
            padding: 10px 0;
            transition: all 0.3s;
            opacity: 0;
            visibility: hidden;
            border-radius: 5px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

            li {
                a {
                    text-transform: capitalize;
                    color: #ffffff;
                    font-weight: 400;
                    padding: 10px 20px;
                    font-size: 15px;

                    &:hover {
                        color: map.get($theme-colors, "main");
                        background-color: rgba(map.get($theme-colors, "main"), 0.2);
                        border-radius: 3px;
                    }
                }
            }
        }

        li:hover .header__menu__dropdown {
            opacity: 1;
            visibility: visible;
            transform: translateY(5px);
            transition: opacity 0.3s ease, transform 0.3s ease;
        }
    }
}



.header__cart {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;

    ul {
        display: flex;
        margin-right: 25px;

        li {
            list-style: none;
            position: relative;
            margin-left: 20px;

            svg {
                font-size: 25px;
                color: map.get($theme-colors, "normal-text")
            }

            span {
                color: white;
                background: map.get($theme-colors, "main");
                position: absolute;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                line-height: 20px;
                font-size: 14px;
                font-weight: 500;
                left: 18px;
                top: -6px;
            }
        }
    }
}

.hero__categories {
    position: relative;

    .hero__categories__all {
        background: map.get($theme-colors, "main");
        padding: 15px 25px 15px 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #ffffff;
        font-weight: 700;
    
        svg,
        span {
            font-size: 16px;
            margin-right: 5px;
        }

        svg {
            font-size: 20px;
            font-weight: bold;
        }
    }

    ul {
        position: absolute;
        opacity: 1;
        border: 1px solid #ebebeb;
        width: 99%;
        background: #ffffff;
        transition: all 0.2s;

        li {
            list-style: none;

            a {
                text-decoration: none;
                background-color: transparent;
                font-size: 16px;
                color: map.get($theme-colors, "normal-text");
                line-height: 39px;
                display: block;
                padding-left: 40px;
            }

            &:hover {
                background: #ffffff;

                a {
                    color: map.get($theme-colors, "main");
                }
            }
        }

        &.hidden {
            opacity: 0;
        }
    }
}

.hero__search_container {

    .hero__search__form {
        width: 610px;
        height: 50px;
        float: left;
        padding-left: 15px;
        margin-bottom: 30px;

        form {
            display: flex;
            align-items: center;
        }

        input {
            border: none;
            height: 48px;
            font-size: 16px;
            color: #b2b2b2;
            padding-left: 20px;
            border: 1px solid #ebebeb;
            width: 70%;
    
            :focus {
                outline: none;
                border: none;
            }
        }
    
        button {
            font-size: 16px;
            color: #ffffff;
            font-weight: 800;
            text-transform: uppercase;
            display: inline-block;
            padding: 16px 30px 17px;
            background: map.get($theme-colors, "main");
            border: none;
            height: 100%;
        }
    }

    .hero__search__phone {
        display: flex;
        align-items: center;

        .hero__search__phone__icon {
            font-size: 25px;
            padding: 10px;
            background: #f5f5f5;
            line-height: 50px;
            text-align: center;
            border-radius: 50%;
            float: left;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: map.get($theme-colors, "main");
        }

        .hero__search__phone__text {
             display: inline-block;

             p {
                color: map.get($theme-colors, "normal-text");
                font-weight: bold;
                margin-bottom: 5px;
             }

             span {
                color: #6f6f6f;

             }
        }
    }

    .hero__item {
        background-image: url($bannerImg);
        background-image: no-repeat;
        background-size: cover;
        height: 410px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 15px;

        .hero__text {
            padding-left: 75px;

            span {
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 4px;
                color: map.get($theme-colors, "main");
            }

            h2 {
                font-size: 45px;
                text-transform: uppercase;
                font-weight: 700;
                line-height: 52px;
                color: map.get($theme-colors, "bold-text");
                margin: 10px 0;
            }

            p {
                font-size: 16px;
                color: #6f6f6f;
                font-weight: 400;
                line-height: 26px;
                margin: 0 0 15px 0;
            }

            a {
                display: inline-block;
                font-size: 14px;
                padding: 10px 28px 10px;
                color: #ffffff;
                text-transform: uppercase;
                font-weight: 700;
                background: map.get($theme-colors, "main");
                letter-spacing: 2px;
                text-decoration: none;
            }
        }
    }
}

@include Responsive($TABLET_DEVICE) {
    .hero__search__form {
        width: 530px !important;
    }
}

@include Responsive($TABLET_DEVICE) {
    .hero__categories {
        margin-bottom: 30px;
    }

    .hero__search__form {
        padding-left: unset !important;
        width: 530px !important;
    }
}

@include Responsive($WIDE_MOBILE) {
    .hero__search__form {
        width: 480px !important;
    }
}


// @include Responsive($TABLET_DEVICE) {
//     .header__top,
//     .header__menu,
//     .header__cart {
//         display: none;
//     }

//     .humberger__open {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         height: 100%;

//         svg {
//             font-size: 30px;
//             color: map.get($theme-colors, "normal-text");
//         }
//     }

//     .humberger__open__wrapper {
//         position: fixed;
//         width: 300px;
//         background: #ffffff;
//         height: 100%;
//         overflow-y: auto;
//         z-index: -9999;
//         padding: 30px;
//         padding-top: 50px;
//         opacity: 0;
//         display: block;
//         transition: all 0.6s;

//         &.show {
//             opacity: 1;
//             z-index: 9999;
//         }
//     }
// }
