@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif;
    background-color: #ebe9e9;
}

.searchBox {
    width: 400px;
    height: 40px;
    background-color: #f0f5ff;
    border-radius: 6px;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    margin-top: 5px;

    svg {
        font-size: 20px;
    }

    .input {
        flex-grow: 1;
        background: transparent;
        border: none;
        outline: none;
        padding-left: 5px;
        font-size: 16px;
        color: #292929;
    }
}
