@use "sass:map";
@import "Style/pages/all";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #f5f5f5; /* Nền nhẹ để làm nổi bật nội dung */
}


.hero-banner {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  .container__categories_slider {
    margin-top: 50px;
  
    .categories_slider {
      .categories_slider_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        cursor: pointer;
  
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        }
  
        img {
          max-width: 120px;
          max-height: 120px;
          margin-bottom: 10px;
        }
  
        p {
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          color: #333;
        }
      }
    }
  }
  
  .featured {
    margin-top: 50px;
  
    .section-title {
      margin-bottom: 50px;
      text-align: center;
  
      h2 {
        position: relative;
        font-size: 2.5rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #333;
      }
  
      h2:after {
        position: absolute;
        content: "";
        width: 80px;
        height: 4px;
        background-color: #ff6347;
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%);
      }
    }
  
    .product-card {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      transition: box-shadow 0.3s ease, transform 0.3s ease;
  
      &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        transform: translateY(-5px);
      }
  
      img {
        max-width: 100%;
        margin-bottom: 15px;
        border-radius: 5px;
        transition: transform 0.3s ease-in-out;
      }
  
      &:hover img {
        transform: scale(1.05);
      }
  
      h3 {
        font-size: 18px;
        font-weight: 600;
      }
  
      .price {
        font-size: 20px;
        color: #ff6347;
        font-weight: 700;
      }
    }
  }
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.container__categories_slider {
  margin-top: 40px !important;

  .categories_slider {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .categories_slider_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 200px;
      padding: 15px;
      background-color: #f7f7f7;
      border-radius: 10px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;
      text-align: center;
      
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      }

      img {
        max-width: 120px;
        max-height: 120px;
        object-fit: contain;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: #333;
      }
    }
  }
}

.featured {
  margin-top: 50px;
  padding-bottom: 50px;

  .section-title {
    margin-bottom: 50px;
    text-align: center;

    h2 {
      position: relative;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      color: #333;
      margin-bottom: 20px;
    }

    h2:after {
      position: absolute;
      left: 50%;
      bottom: -10px;
      width: 100px;
      height: 4px;
      background-color: #ff6347;
      content: '';
      transform: translateX(-50%);
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  .product-card {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    background-color: #fff;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    max-width: 300px; /* Giới hạn chiều rộng của thẻ sản phẩm */
    width: 100%; /* Đảm bảo các thẻ sản phẩm có cùng chiều rộng */

    &:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
    }

    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 15px;
      border-radius: 5px;
      transition: transform 0.3s ease-in-out;
    }

    &:hover img {
      transform: scale(1.1);
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }

    .price {
      font-size: 18px;
      color: #ff6347;
      font-weight: bold;
      margin-top: 10px;
    }
  }
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff6347;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff4500;
  }
}

/* Đáp ứng cho các kích thước màn hình khác nhau */
@include Responsive($MEDIUM_DEVICE) {
  .container {
    max-width: 990px !important;
  }
}

@include Responsive($TABLET_DEVICE) {
  .container {
    max-width: 750px !important;
  }
}

@include Responsive($WIDE_MOBILE) {
  .container {
    max-width: 540px !important;
  }
} 