.custom-modal .modal-dialog {
    max-width: 700px;
    margin: 100px auto;
}

.custom-modal .modal-content {
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-modal .modal-header {
    background-color: #007bff;
    color: #fff;
}

.custom-modal .modal-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.custom-modal .modal-body {
    font-size: 1rem;
    color: #333;
}

.custom-modal .modal-footer {
    justify-content: center;
}

.custom-modal .btn-primary {
    background-color: #007bff;
    border: none;
}

.custom-modal .btn-secondary {
    background-color: #6c757d;
    border: none;
}

.table-bordered {
    border: 1px solid #ddd;
}

.table-bordered th, .table-bordered td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.product-details {
    margin-top: 20px;
  
    table {
        width: 100%;
        border: 1px solid #ddd;
  
        th, td {
            padding: 10px;
            text-align: center;
            border: 1px solid #ddd;
        }
  
        img {
            width: 100px;
            height: auto;
        }
    }
}

.custom-options-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px; /* Khoảng cách giữa các item */
    margin-top: 10px;
    flex-wrap: wrap; /* Điều này đảm bảo các item sẽ nằm ngang nhưng sẽ xuống dòng nếu không đủ chỗ */
}

.custom-option-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Điều chỉnh các lựa chọn custom nằm ngang */
.custom-options-container .form-check {
    display: inline-block;
    margin-right: 15px; /* Khoảng cách giữa các lựa chọn */
}

.custom-options-container input[type="text"] {
    width: 150px; /* Độ rộng của ô điền chữ */
}

.custom-options-container input[type="file"] {
    display: inline-block;
    width: auto;
}

.custom-options-container {
    display: flex;
    gap: 20px; /* Khoảng cách giữa các tùy chọn */
    flex-wrap: wrap; /* Đảm bảo các tùy chọn xuống dòng nếu không đủ chỗ */
    margin-top: 10px;
  }
  
  .custom-option-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .custom-options-container {
    display: flex;
    gap: 20px; /* Khoảng cách giữa các tùy chọn */
    flex-wrap: wrap; /* Đảm bảo các tùy chọn xuống dòng nếu không đủ chỗ */
    margin-top: 10px;
  }
  
  .custom-option-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  