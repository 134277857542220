header {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .container-fluid {
        padding: 0;
    }

    .row {
        width: 100%;
        margin: 0;
        display: flex;
        align-items: center;
    }

    h1 {
        margin-left: 20px;
        font-size: 28px;
        font-weight: bold;
        color: #292929;
    }

    /* Adjusting button size and positioning */
    button.rounded-circle-1 {
        width: 40px;
        height: 40px;
        background-color: #f0f5ff;
        color: #292929;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 100px;
    }

    button.rounded-circle-1 svg {
        font-size: 22px; /* Adjust icon size */
        color: #292929;
    }

    button.rounded-circle-2 svg {
        font-size: 22px; /* Adjust icon size */
        color: #292929;
    }

    /* Search box styling */
    .searchBox {
        flex-grow: 1;
        margin-left: 10px;
        margin-bottom: 5px; /* Reduce margin for tighter layout */
    }

    .col-sm-4 {
        display: flex;
        align-items: center;
    }

    .col-sm-2 {
        display: flex;
        align-items: center;
    }

    /* Adjusting the right-side menu buttons alignment */
    .col-sm-6 {
        display: flex;
        justify-content: flex-end;

        button.rounded-circle-1 {
            margin-right: 0px; /* Equal spacing between menu buttons */
        }

        button.rounded-circle-2 {
            margin-right: 0px; /* Equal spacing between menu buttons */
        }
    }

    button.rounded-circle-2 {
        width: 40px;
        height: 40px;
        background-color: #f0f5ff;
        color: #292929;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
    }

    .button-condition {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 300px;
    }
    
    /* Adjusting user account button */
    .myAccWrapper {
        margin-left: 20px;
        display: flex;
        align-items: center;
        position: relative;

        .header__menu__dropdown {
            background-color: #222222;
            display: block;
            position: absolute;
            top: 100%; 
            left: 0;
            width: 180px;
            z-index: 9;
            padding: 5px 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;
            list-style: none;
            // text-align: center;

            li {
                a {
                    display: block;
                    text-transform: capitalize;
                    color: #ffffff;
                    font-weight: 400;
                    padding: 10px 15px;
                    text-decoration: none;

                    &:hover {
                        background-color: #333333;
                        color: #0858f7; /* Replace if not using SCSS map */
                    }
                }
            }
        }

        &:hover .header__menu__dropdown {
            opacity: 1;
            visibility: visible;
        }
    }

    .myAcc {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rounded-circle-3 {
        width: 40px;
        height: 40px;
        background-color: #f0f5ff;
        color: #292929;
        border: 1px solid #0858f7;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rounded-circle-3 svg {
        font-size: 20px;
        color: #292929;
    }

    .userInfo {
        margin-left: 10px;
        display: flex;
        align-items: center;
    }

    .userInfo p {
        margin: 0;
        font-size: 14px;
        color: #000;
        opacity: 0.8;
    }
}

/* Set base styles for buttons */
header button.rounded-circle-1, 
header button.rounded-circle-2 {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
    background-color: #f0f5ff !important;
    color: #0858f7;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out; /* Add transition for smooth hover effect */
}

/* Set initial color for SVG icons */
header button.rounded-circle-1 svg, 
header button.rounded-circle-2 svg {
    color: #292929;
    font-size: 18px !important;
    transition: color 0.2s ease-in-out; /* Add transition for icon color change */
}

/* Hover effect for buttons */
header button.rounded-circle-1:hover svg, 
header button.rounded-circle-2:hover svg {
    color: #0858f7 !important;
}

/* Optional hover effect for button background */
header button.rounded-circle-1:hover, 
header button.rounded-circle-2:hover {
    background-color: #e0ecff !important; /* Slightly darker background on hover */
}


