/* General Styling */
body {
  font-family: "Arial", sans-serif;
  background-color: #f4f6f9;
}

.register-container {
  z-index: 2;
  max-width: 400px;
  margin: 80px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.login-logo {
  position: absolute;
  z-index: 1;
  width: 50%;
  height: auto;
  border-radius: 50%;
}

.register-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 32px;
  color: #2c3e50;
}

.register-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #7f8c8d;
}

.form-control {
  width: 95%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-control:focus {
  outline: none;
  border-color: #3498db;
}

.register-button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #2980b9;
}

.signin-link {
  text-align: center;
  margin-top: 15px;
}

.signin-link p {
  display: inline;
  font-size: 14px;
  margin-right: 5px;
  color: #7f8c8d;
}

.signin-link a {
  color: #3498db;
  text-decoration: none;
}

.signin-link a:hover {
  text-decoration: underline;
}

/* Media Queries */
@media (max-width: 600px) {
  .register-container {
    width: 90%;
    margin: 50px auto;
  }
}
