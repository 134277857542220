.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: #f8f9fa;

    .product {
        text-align: center;
        margin-top: 100px;

        h2 {
            margin-bottom: 20px;
        }

        .product-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px;

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }

            .product-image {
                width: 250px;

                img {
                    width: 550px;
                    height: 400px;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }

                @media (max-width: 768px) {
                    width: 100%;
                    img {
                        margin-left: 0;
                        width: 100%;
                        max-width: 350px;
                        height: auto;
                    }
                }
            }

            .product-options {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 20px;
                background-color: #ffffff;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

                .color-options {
                    display: flex;
                    justify-content: center;
                    gap: 10px;

                    .color-button {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        border: none;
                        cursor: pointer;
                        transition: border-color 0.3s ease;

                        &.selected {
                            border: 3px solid #007bff;
                        }
                    }
                }

                .quantity-options {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    h4 {
                        margin-right: 10px;
                    }

                    .quantity-btn {
                        width: 40px;
                        height: 40px;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        cursor: pointer;
                        font-size: 18px;
                        transition: background-color 0.3s;

                        &:hover {
                            background-color: #007bff;
                            color: white;
                        }
                    }

                    .quantity-display {
                        font-size: 18px;
                    }
                }

                .summary {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    p {
                        margin: 0;
                        font-size: 16px;
                    }

                    .cart-btn {
                        padding: 10px;
                        background-color: #007bff;
                        color: white;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        transition: background-color 0.3s;

                        &:hover {
                            background-color: #0056b3;
                        }
                    }
                }
            }
        }
    }
}
