@use "sass:map";
@import "Style/pages/theme";
@import url('http://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {font-family:  "Open Sans", sans-serif;}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
}

h1 {
    color: map.get($theme-colors, "bold-text");
}

h2 {
    margin-bottom: 10px;
    color: map.get($theme-colors, "bold-text");
}

h3{
    margin-bottom: 10px;
    color: map.get($theme-colors, "bold-text");
}


h5 {
    margin-bottom: 10px;
    color: map.get($theme-colors, "bold-text");
}

/* Hero Section */
.product-image-hero {
    width: 770px;
    height: 400px;
}

.hero-section {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}

.hero-section .hero-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.about-section {
    text-align: center;
    padding: 20px;
    // background-color: #ffffff;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: -50px auto;
    max-width: 800px;
}

.about-section h1 {
    font-size: 36px;
}

.about-section h5 {
    font-size: 14px;
    color: map.get($theme-colors, "bold-text");
}

.customization-info h5 {
    font-size: 14px;
    color: map.get($theme-colors, "bold-text");
}

/* Product Showcase */
.product-showcase, .additional-products {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0;
}

.product-showcase .product-image,
.additional-products .product-image {
    width: 360px;
    height: 400px;
    object-fit: cover;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.product-showcase .product-image:hover,
.additional-products .product-image:hover {
    transform: scale(1.05);
}

/* Customization Info Section */
.customization-info {
    text-align: center;
    padding: 20px;
    color: #fff;
    font-weight: bold;
}

/* Contact Section */
.contact-section {
    text-align: center;
    padding: 40px 0;
    background-color: #2c3e50;
    color: #fff;
}

.contact-section h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

.contact-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-section ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.contact-section ul li {
    display: inline-block;
}

.contact-section ul li a {
    color: #fff;
    font-size: 32px;
    transition: color 0.3s ease;
}

.contact-section ul li a:hover {
    color: #3498db;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .product-showcase, .additional-products {
        flex-direction: column;
        align-items: center;
    }

    .product-showcase .product-image,
    .additional-products .product-image {
        width: 100%;
        max-width: 300px;
        margin-bottom: 20px;
    }

    .about-section, .customization-info {
        margin: 0 20px;
    }
}

.contact-section-1 {
    text-align: center;
    padding: 130px 0;
    background-color: #3378bd;
}