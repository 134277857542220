@use "sass:map";
@import "theme";
@import "responsive";


html,
body {
    margin: 0;
}

*{
    font-family: Roboto;
    padding: 0;
    margin: 0;
}

.container {
    max-width: 1170px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    // background: map.get($theme-colors, "main");
    margin: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.button-submit {
    font-size: 14px;
    color: #ffffff;
    font-weight: 800;
    text-transform: uppercase;
    display: inline-block;
    padding: 16px 30px 17px;
    background: map.get($theme-colors, "main");
    border: none;
    height: 100%;
}

.pl-pr-10 {
    padding-left: 10px;
    padding-right: 10px;
}

input, textarea {
    width: 100%;
    height: 35px;
    border: 1px solid #ebebeb;
    padding-left: 10px;
    font-size: 14px;
    color: #b2b2b2;
    border-radius: 3px;

    &:focus {
        outline: none;
    }
}

textarea {
    padding-top: 10px;
}

@include Responsive($DESKTOP) {
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@include Responsive($TABLET_DEVICE) {
    .container {
        max-width: 720px;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@include Responsive($MEDIUM_DEVICE) {
    .container {
        max-width: 960px;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@include Responsive($WIDE_MOBILE) {
    .container {
        max-width: 540px;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@include Responsive($SMALL_DEVICES) {
    .container {
        max-width: 350px;
    }

    .col-xs-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}