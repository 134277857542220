/* Shopping Cart Styles */
.container {
    margin-top: 30px;
}

.row {
    display: flex; 
    justify-content: center;
}

.col-12 {
    text-align: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.table {
    width: 100%; 
    border-collapse: separate; 
    border-spacing: 0;
    table-layout: auto;
}

.table img {
    border-radius: 4px;
}

.table th {
    padding: 1rem;
    vertical-align: middle;
    text-align: center;
    background-color: #f8f9fa;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 600;
}

.table td {
    padding: 1rem;
    vertical-align: middle;
    text-align: center;
    font-size: 1rem;
}

.table .form-control {
    width: 60px;
    text-align: center;
    margin: 0 auto;
}

.btn-danger {
    padding: 5px 10px;
    font-size: 0.875rem;
    border: none;
    background-color: #e74c3c; 
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-danger:hover {
    background-color: #c0392b; 
}

.text-end {
    justify-content: center;
    text-align: center;
}

.text-end h5 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #e74c3c;
    margin-top: 20px;
    // text-align: end;
}

/* Responsive Design */
@media (max-width: 768px) {
    .table-responsive {
        overflow-x: auto;
    }

    .text-end {
        text-align: center;
    }
    
    .table td .product-name img {
        width: 50px; /* Adjusting image size for smaller devices */
    }

    .table td .product-name {
        font-size: 0.875rem;
        justify-content: center;
    }
}

.table td .product-name {
    font-size: 1rem;
    font-weight: 500;
    color: #343a40;
    margin: 0;
    line-height: 1.4;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: left;
}

.table td .product-name img {
    border-radius: 4px;
    width: 60px; /* Adjusted image size */
    height: auto;
    margin-right: 10px;
}

.table td .product-name span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 80px); /* Ensure text fits alongside the image */
}

.text-end button {
    background-color: #28a745; /* Green color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 10px;
}

.text-end button:hover {
    background-color: #218838; /* Darker green on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.text-end button:active {
    transform: scale(0.95); /* Shrink slightly on click */
}

.text-end button:focus {
    outline: none; /* Remove focus outline */
}

.text-end button a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent button */
}

.text-end button a:focus {
    outline: none; /* Remove focus outline */
}
.custom-options-container {
    gap: 10px;
    margin-top: 10px;
}

.custom-option-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .text-end button {
        width: 100%; /* Full width for mobile */
        padding: 15px;
    }
}

/* Styles for the modal styled like a notification box */
.custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 9999;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
}

.custom-modal h5 {
    font-size: 1.25rem;
    margin-bottom: 20px;
    text-align: center;
}

.custom-modal form {
    width: 100%;
}

.custom-modal form .form-group {
    margin-bottom: 15px;
}

.custom-modal form .form-control {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1rem;
}

.custom-modal form button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-modal form button:hover {
    background-color: #218838;
}

.custom-modal form .btn-secondary {
    background-color: #6c757d;
    margin-top: 10px;
}

.custom-modal form .btn-secondary:hover {
    background-color: #5a6268;
}

/* Fade-in animation for the modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}
