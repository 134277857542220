@use "sass:map";
@import "Style/pages/theme";
@import url('http://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {font-family:  "Open Sans", sans-serif;}

.container {
    padding: 5px 0;

    .sidebar {
        padding-right: 20px;

        .sidebar__item {
            margin-bottom: 20px;

            h2 {
                color: rbg(28, 28, 28);
                font-family: 700;
                margin-bottom: 10px;
            }

            .price-range-wrap {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
            }

            .tags {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;

                .tag {
                    font-size: 10px;
                    color: #6f6f6f;
                    display: inline;
                    padding: 8px 25px 6px;
                    cursor: pointer;
                    background: #f5f5f5;
                    margin-bottom: 0;

                    &:hover, &:active {
                        background: map.get($theme-colors, "main");
                        color: #ffffff;
                    }
                }
            }

            ul {
                list-style: none;

                a {
                    text-decoration: none;
                    line-height: 30px;
                    color: map.get($theme-colors, "normal-text");

                    &:hover {
                        color: map.get($theme-colors, "main");
                    }
                }
            }
        }
    }
}