/* Reset default margins and paddings */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f0f2f5; /* Soft background to contrast the slider */
    color: #333;
}

/* Main container styles */
main {
    position: relative;
    width: 100%;
    max-width: 1200px;
    height: 500px;
    margin: 2rem auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

/* Slider styles */
.slider {
    position: relative;
    width: 100%;
    height: 100%;
}

/* Individual item styles */
.item {
    width: 100%;
    height: 100%;
    list-style-type: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-position: center;
    background-size: cover;
    border-radius: 15px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3); 
    opacity: 0; 
    transition: opacity 0.75s ease-in-out;
    display: flex;
    align-items: center;

    &.active {
        opacity: 1;
    }
}

/* Content inside each item */
.content {
    width: min(30vw, 400px);
    margin-left: 3rem;
    padding: 1.5rem;
    background: rgba(0, 0, 0, 0.6); 
    border-radius: 10px;
    // color: white;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    // opacity: 0;
    transition: opacity 0.75s ease-in-out;
    backdrop-filter: blur(5px);

    .title {
        font-family: 'Arial Black', sans-serif;
        font-size: 1.8rem;
        color: #ffe100;
        text-transform: uppercase;
        margin-bottom: 1rem;
        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    .description {
        font-size: 1.2rem;
        line-height: 1.6;
        margin: 0;
        color: #e0e0e0;
    }
}

/* Navigation styles */
.nav {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    display: flex;
    gap: 0.5rem;

    .btn {
        background-color: rgba(255, 255, 255, 0.8);
        color: rgba(0, 0, 0, 0.8);
        border: 2px solid rgba(0, 0, 0, 0.6);
        padding: 0.6rem;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: rgba(255, 255, 255, 0.6);
        }
    }
}

/* Responsive design for smaller screens */
@media (max-width: 900px) {
    .content {
        padding: 1rem;

        .title {
            font-size: 1.4rem;
        }

        .description {
            font-size: 1rem;
        }
    }
}

@media (max-width: 650px) {
    main {
        height: 400px;
    }

    .content {
        width: 85%;
        margin-left: 2rem;

        .title {
            font-size: 1.2rem;
        }

        .description {
            font-size: 0.9rem;
        }
    }
}
