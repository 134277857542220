@use "sass:map";
@import "Style/pages/all";
.featured__item__pic__hover {
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
}

.featured__item__pic__hover li {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 50%;
    color: white;
    transition: background-color 0.3s ease;
}

.featured__item__pic__hover li:hover {
    background-color: rgba(0, 0, 0, 0.8);
}


.featured__item {
    margin-bottom: 50px;

    &:hover {
        .featured__item__pic__hover {
            top: 80% !important; /* Bring the hover effect into view */
        }
    }

    .featured__item__pic {
        height: 270px;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;

        .featured__item__pic__hover {
            position: absolute;
            left: 0;
            top: 120%;
            width: 100%;
            text-align: center;
            transition: all 0.5s ease-in-out;

            li {
                list-style: none;
                display: inline-block;
                margin-right: 10px;
                cursor: pointer;

                &:hover {
                    svg {
                        background: #7fad39;
                        border-color: #7fad39;
                        color: #ffffff;
                        transform: rotate(360deg);
                    }
                }

                svg {
                    padding: 10px;
                    height: 20px;
                    width: 20px;
                    border: 1px solid #ebebeb;
                    background: #ffffff;
                    color: #565555;
                    transition: all 0.3s ease;
                    border-radius: 50%;
                }
            }
        }
    }

    .featured__item__text {
        text-align: center;

        h6 {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 400;

            a {
                color: map.get($theme-colors, "normal-text");
                text-decoration: none;
            }
        }

        h5 {
            color: map.get($theme-colors, "normal-text");
            font-size: 18px;
            font-weight: 700;
            margin-top: 10px;
            color: map.get($theme-colors, "main");
        }
    }
}    