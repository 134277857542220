.payment-page {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    h2 {
        font-size: 24px;
        margin-bottom: 20px;
        text-align: center;
        color: #333;
    }

    form {
        margin-top: 50px; // Add margin top to form
    }

    .form-group {
        margin-bottom: 15px;

        label {
            display: block;
            font-weight: bold;
            margin-bottom: 5px;
            color: #555;
        }

        input,
        select {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            font-size: 16px;
            box-sizing: border-box;
        }

        input:focus,
        select:focus {
            border-color: #007bff;
            outline: none;
        }
    }

    .credit-card-form {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 15px;
        background-color: #fff;
    }

    .cash-payment-info {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        color: #555;
        text-align: center;
    }

    .btn {
        display: block;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        color: #fff;
        background-color: #007bff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }
}