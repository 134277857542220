.main {
    display: flex;
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Prevents scrolling if content overflows */

    .sidebarWrapper {
        flex: 0 0 250px; /* Fixed width for sidebar */
        background-color: #f4f4f4;
        height: 100%; /* Full height */
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
        position: relative; /* Sidebar stays fixed */
    }

    .content {
        flex: 1; /* Takes up the remaining space */
        padding: 20px;
        background-color: #ffffff;
        overflow-y: auto;

        .card {
            border-radius: 8px;
            .hd {
                font-size: 1.5rem;
                font-weight: bold;
                color: #333;
            }

            .form-control-1 {
                width: 50%;
                padding: 10px;
                font-size: 16px;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
        }

        /* Table Styles */
        table.table {
            width: 100%;
            border-collapse: collapse;
            thead {
                background-color: #f8f9fa;
                
                th {
                    text-align: center;
                    padding: 12px;
                    font-weight: 600;
                    color: #fff;
                    background-color: #385773;
                }
            }
            tbody {
                td {
                    padding: 12px;
                    vertical-align: middle;
                    border-top: 1px solid #dee2e6;
                }
                /* Styling the Create and Delete buttons */
                button {
                    padding: 6px 12px;
                    border-radius: 4px;
                    font-size: 0.875rem;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .btn-success {
                    background-color: #28a745;
                    border: none;
                    color: #fff;
                    &:hover {
                        background-color: #218838;
                    }
                }
                .btn-danger {
                    background-color: #dc3545;
                    border: none;
                    color: #fff;
                    &:hover {
                        background-color: #c82333;
                    }
                }
            }
        }

        /* Pagination Controls */
        .d-flex {

            button {
                padding: 6px 16px;
                border-radius: 4px;
                background-color: #007bff;
                color: #fff;
                border: none;
                &:disabled {
                    background-color: #6c757d;
                }
                &:hover {
                    background-color: #0056b3;
                }
            }
            span {
                font-size: 1rem;
                color: #495057;
            }
        }
    }
}

.button-search {
    margin-left: 10px;
    cursor: pointer;
}

.justify-content-between {
    display: flex;
    justify-content: end;
    align-items: center;

    button {
        margin: 0px 10px;
        cursor: pointer;
    }
}

.action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Adds space between the buttons */
}

/* Responsive Design */
@media (max-width: 768px) {
    .main {
        flex-direction: column;
        .sidebarWrapper {
            flex: 0 0 200px; /* Shrink sidebar */
        }
        .content {
            padding: 15px; /* Adjust content padding */
        }
    }
}

@media (max-width: 576px) {
    .content {
        padding: 10px; /* Smaller padding for mobile devices */
        table.table {
            font-size: 0.875rem; /* Adjust table font size for mobile */
        }
    }
}
