.error-message {
  color: #e74c3c;
  font-size: 14px;
  margin-bottom: 15px;
}

.reset-container {
  max-width: 400px;
  margin: 80px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.login-logo {
  position: absolute;
  z-index: 1;
  width: 50%;
  height: auto;
  border-radius: 50%;
}

.reset-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 32px;
  color: #2c3e50;
}

.reset-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 95%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-control:focus {
  outline: none;
  border-color: #3498db;
}

.reset-button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:disabled {
    background-color: #3498db;
    cursor: not-allowed;
  }
}

.reset-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.signin-option {
  text-align: center;
  margin-top: 15px;
}

.signin-option p {
  display: inline;
  font-size: 14px;
  margin-right: 5px;
  color: #7f8c8d;
}

.signin-option a {
  color: #3498db;
  text-decoration: none;
}

.signin-option a:hover {
  text-decoration: underline;
}
