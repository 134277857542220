/* Main Container */
.main {
    display: flex;
    height: 100vh;
    overflow: hidden;

    .sidebarWrapper {
        flex: 0 0 250px; /* Sidebar width */
        background-color: #f8f9fa;
        height: 100%;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    }

    .content {
        flex: 1;
        padding: 20px;
        background-color: #f1f3f5;
        overflow-y: auto;

        .card {
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

            form {
                .form-group {
                    margin-bottom: 1rem;
                    width: 100%;

                    label {
                        display: block;
                        margin-bottom: 0.5rem;
                        font-weight: bold;
                        color: #333;
                    }

                    input {
                        width: 100%;
                        padding: 10px;
                        font-size: 1rem;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        transition: border-color 0.3s ease;

                        &:focus {
                            outline: none;
                            border-color: #007bff;
                        }
                    }
                }

                button[type="submit"] {
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    background-color: #28a745;
                    border: none;
                    border-radius: 4px;
                    color: #ffffff;
                    font-size: 1rem;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    svg {
                        margin-right: 5px;
                        font-size: 1.25rem;
                    }

                    &:hover {
                        background-color: #218838;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
                    }
                }
            }
        }
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .main {
        flex-direction: column;
        
        .sidebarWrapper {
            flex: 0 0 200px; /* Shrink sidebar on smaller screens */
        }

        .content {
            padding: 15px;
        }
    }
}

@media (max-width: 576px) {
    .content {
        padding: 10px;

        button[type="submit"] {
            width: 100%;
            justify-content: center;
        }
    }
}
