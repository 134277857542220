.sidebar {
    background-color: #f4f4f4;
    width: 250px;
    height: 100vh; /* Full height of the viewport */
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    overflow-y: auto;

    .sidebar__logo {
        text-align: center;
        margin-bottom: 20px;

        img {
            max-width: 100%;
            width: 70%;  
            height: auto;     
            display: block;  
            margin: 0 auto;
            border-radius: 50%; 
        }
    }

    h3 {
        margin-bottom: 20px;
        color: #333;
        font-size: 18px;
    }

    .sidebar__menu {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 15px;
            display: flex;
            align-items: center;

            a {
                text-decoration: none;
                color: #0858f7;
                font-weight: bold;
                display: flex;
                align-items: center;
                padding: 10px;
                border-radius: 5px;
                transition: background-color 0.3s, color 0.3s;
                width: 100%;

                svg {
                    margin-right: 10px; /* Spacing between icon and text */
                    font-size: 18px; /* Adjust icon size */
                }

                &:hover {
                    background-color: #e0ecff;
                    color: #000;
                }
            }

            // .badge {
            //     background-color: red;
            //     color: white;
            //     padding: 3px 7px;
            //     border-radius: 50%;
            //     font-size: 12px;
            //     margin-left: auto; /* Pushes the badge to the right */
            // }
        }
    }
}


@media (max-width: 768px) {
    .sidebar {
        width: 200px;
        position: absolute;
        z-index: 1000;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
    }

    .sidebar.open {
        transform: translateX(0);
    }
}

