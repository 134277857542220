.dashboardBox {
    width: 47%;
    min-width: 250px;
    height: 200px;
    background-color: #ff5252; // Fallback for the gradient
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 15px;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }

    .chart {
        opacity: 0.1;
        position: absolute;
        bottom: 20px;
        left: 20px;

        svg {
            font-size: 110px;
            color: #000;
        }
    }

    .d-flex {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .col1 {
        h4 {
            font-size: 20px;
            font-weight: 600;
            color: #fff;
        }

        span {
            font-size: 36px; // Adjusted font size for better visual hierarchy
            font-weight: bold;
            color: #fff;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
        margin-left: 140px;

        svg {
            font-size: 40px;
            opacity: 0.2;
            color: #000;
        }
    }

    .bottomEle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: 5%;

        h6 {
            font-size: 14px;
            margin: 0;
            color: #fff;
        }

        .toggleIcon {
            font-size: 20px;
            cursor: pointer;
        }
    }
}

.dropdownMenu {
    position: absolute;
    top: 40px; // Adjusted for better alignment
    right: 0;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    z-index: 10;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 8px 12px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #f1f1f1;
            }
        }
    }
}

// Media query for smaller screens
@media (max-width: 768px) {
    .dashboardBox {
        width: 100%;
        margin-bottom: 15px;
    }

    .col1 {
        h4 {
            font-size: 18px; // Adjusted for smaller screens
        }

        span {
            font-size: 32px; // Adjusted for smaller screens
        }
    }
}
