* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif;
    background: #ebe9e9;
}

.right-content {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
}

.dashboardBoxWrapperRow {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dashboardBoxWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    justify-content: center;
}

.dashboardBox {
    width: calc(50% - 15px); // Adjust for gap
    min-width: 250px;
    height: 200px;
    background-color: #ff5252; // Fallback color
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

.icon svg {
    font-size: 40px;
    opacity: 0.2;
    color: #000;
}

.chart {
    opacity: 0.1;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.chart svg {
    font-size: 110px;
    color: #000;
}

.bottomEle {
    position: relative;
    top: 25px;
}

.col-md-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
}

.col-md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}

.box {
    background: #0066ff;
    width: 100%;
    max-width: 350px;
    height: 414px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.graphBox {
    background-image: linear-gradient(#1a50b5, #2a6ff7);
    border-radius: 10px;
    padding: 25px;
}

.graphBox h4 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 50px;
    color: #fff;
}

.graphBox h3 {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
}

.graphBox p {
    font-size: 15px;
    color: #fff;
    opacity: 0.7;
}

.card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px; // Ensure card has padding
    .hd {
        font-size: 1.5rem;
        font-weight: bold;
    }
}

.table {
    margin-top: 20px;
    width: 100%;
    background-color: #ffffff;
    border-collapse: separate;
    border-spacing: 0 10px;
    th, td {
        padding: 15px;
        vertical-align: middle;
        text-align: center;
    }

    thead th {
        background-color: blue;
        color: #ffffff;
        border: none;
    }

    tbody tr {
        background-color: #f8f9fa;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    tbody tr:hover {
        background-color: #e2e6ea;
    }

    tbody th {
        background-color: #f8f9fa;
        color: #333;
        border: none;
    }

    tbody td {
        border: none;
    }
}

.pagination-controls {
    display: flex;
    justify-content: space-between; // Spread pagination buttons
    margin-top: 20px; // Space from table
    button {
        padding: 5px 15px;
        border-radius: 5px;
        font-size: 1rem;
        transition: background-color 0.3s ease;
        &:hover {
            background-color: #007bff;
            color: #fff;
        }
        &:disabled {
            background-color: #ccc;
        }
    }
}

.input-search {
    display: flex;
    width: 50%;
}

.button-search {
    margin-left: 10px;
    cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .dashboardBox {
        width: 100%; // Full width on small screens
        margin-bottom: 15px;
    }

    .box {
        width: 100%;
        max-width: none;
        margin-left: 0;
    }

    .graphBox {
        h3 {
            font-size: 1.5rem;
        }
        p {
            font-size: 0.875rem;
        }
    }

    .table {
        th, td {
            padding: 10px;
            font-size: 0.875rem;
        }
    }

    .pagination-controls {
        button {
            font-size: 0.875rem;
            padding: 8px 12px;
        }
    }
}
